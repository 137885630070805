import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface Page {
  path: string;
  hideNavigation?: boolean;
  shouldSkipBack?: boolean;
  active: boolean;
}

// Define a type for the slice state
interface NavigationState {
  pagePaths: Page[];
  freezePaths: boolean;
}

// Define the initial state using that type
const initialState: NavigationState = {
  pagePaths: [
    { path: "/", hideNavigation: true, active: true },
    { path: "/org-location", hideNavigation: false, active: true },
    { path: "/tags-challenge", active: true },
    { path: "/value", active: true },
    { path: "/nutrition-add-on", active: false },
    { path: "/tags1", active: true },
    { path: "/tags2", active: true },
    { path: "/snippet1", active: true },
    { path: "/tags3", active: true },
    { path: "/tags4", active: true },
    { path: "/exercise", active: true },
    { path: "/trainer", active: true },
    { path: "/frequency", active: true },
    { path: "/equipment", active: false },
    { path: "/weight-system", active: true },
    { path: "/height-weight", active: true },
    { path: "/gender", active: true },
    { path: "/phone", active: true },
    { path: "/smart-watch", active: true },
    {
      path: "/finding-coach",
      hideNavigation: true,
      shouldSkipBack: true,
      active: true,
    },
    { path: "/select-coach", active: true },
    { path: "/setup-freemium", active: false },
    { path: "/finish-freemium", active: false, hideNavigation: true },
    { path: "/plan", active: true },
    { path: "/pay", active: true },
    { path: "/schedule-call", hideNavigation: true, active: true },
    { path: "/address", hideNavigation: true, active: true },
    { path: "/source", hideNavigation: true, active: true },
    { path: "/done", hideNavigation: true, active: true },
  ],
  freezePaths: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    resetPagePaths: (state) => {
      if (state.freezePaths) {
        return;
      }

      state.pagePaths = initialState.pagePaths;
    },
    setPagePaths: (state, action: PayloadAction<Page[]>) => {
      if (state.freezePaths) {
        return;
      }

      const paths = action.payload;

      state.pagePaths = paths;
    },
    setPagePathStatus: (
      state,
      action: PayloadAction<{ path: string; status: "active" | "inactive" }>,
    ) => {
      if (state.freezePaths) {
        return;
      }

      const { path, status } = action.payload;

      const index = state.pagePaths.findIndex(
        (pagePath) => pagePath.path === path,
      );

      if (index !== -1) {
        state.pagePaths[index].active = status === "active" ? true : false;
      }
    },
    setPagePathOptions: (
      state,
      action: PayloadAction<{
        path: string;
        options: {
          hideNavigation?: boolean;
          shouldSkipBack?: boolean;
          active?: boolean;
        };
      }>,
    ) => {
      if (state.freezePaths) {
        return;
      }

      const { path, options } = action.payload;

      const index = state.pagePaths.findIndex(
        (pagePath) => pagePath.path === path,
      );

      if (index !== -1) {
        state.pagePaths[index] = { ...state.pagePaths[index], ...options };
      }
    },
    deactivateAllPaths: (state) => {
      if (state.freezePaths) {
        return;
      }

      for (const path of state.pagePaths) {
        path.active = false;
      }
    },
    forceFreemiumPaths: (state) => {
      state.freezePaths = true;

      for (const path of state.pagePaths) {
        path.active = false;
      }

      const activePaths = [
        "/",
        "/equipment",
        "/weight-system",
        "/height-weight",
        "/gender",
        "/phone",
        "/smart-watch",
        "/setup-freemium",
        "/finish-freemium",
      ];

      for (const path of activePaths) {
        const index = state.pagePaths.findIndex(
          (pagePath) => pagePath.path === path,
        );

        if (index !== -1) {
          state.pagePaths[index].active = true;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPagePaths,
  setPagePathOptions,
  setPagePathStatus,
  setPagePaths,
  deactivateAllPaths,
  forceFreemiumPaths,
} = navigationSlice.actions;

export default navigationSlice.reducer;
