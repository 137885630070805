import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import { setTagAddOns } from "src/slices/surveySlice";

export default function NutritionAddOnContainer() {
  const { nextPage } = useSurvey();
  const dispatch = useAppDispatch();

  const continueWithNutrition = () => {
    dispatch(setTagAddOns(["nutrition"]));
    nextPage();
  };

  const continueWithoutNutrition = () => {
    nextPage();
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Stack direction="column" gap="4px" mb="20px">
        <Typography variant="h1" gutterBottom>
          Do you want to add on Nutrition?
        </Typography>
        <Typography variant="subtitle1">
          Track your food, get weight loss insights, and coaching from your
          dedicated trainer.
        </Typography>
      </Stack>

      <Box
        component="img"
        src="/assets/trainwell-nutrition.png"
        sx={{
          my: 1,
          display: "flex",
          justifyContent: "center",
          objectFit: "contain",
          height: "40vh",
        }}
      />

      <Stack direction="column" gap="16px">
        <Button onClick={continueWithNutrition}>Yes</Button>
        <Button onClick={continueWithoutNutrition}>No</Button>
      </Stack>
    </Container>
  );
}
