import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  type ProjectedInfluencer,
  getInfluencerBySourceId as apiGetInfluencerBySourceId,
} from "@trainwell/features/influencers";
import { registerProp } from "src/lib/btracking";
import { setCookie } from "src/lib/cookie";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";
import { setClientID } from "./clientSlice";
import { fetchSubscriptionGroup } from "./paymentSlice";
import { shouldShowIdentity } from "./surveySlice";

export const startSession = createAsyncThunk(
  "analytics/startSession",
  async (
    obj: {
      data: {
        userId?: string;
        utmContent?: string;
        utmMedium?: string;
        utmSource?: string;
        utmCampaign?: string;
        sourceId?: string;
        referrer?: string;
        referralId?: string;
        referralCode?: string;
        referralCTAId?: string;
        referrerUserId?: string;
        referralCampaign?: string;
        forceTest?: string[];
        debugTest?: string;
        testUser?: boolean;
        brandUserId?: string;
        isDebugger?: boolean;
        organizationId?: string;
        b2bBillingUserId?: string;
      };
      trainerId?: string;
    },
    { dispatch },
  ) => {
    const { data, trainerId } = obj;

    // get query param for source
    const urlParams = new URLSearchParams(window.location.search);
    const sourceId = urlParams.get("source");
    dispatch(getInfluencerBySourceId(sourceId));

    const response = await api.analytics
      .startSession({ ...data, platform: "web" })
      .catch(() => api.analytics.startSession({ ...data, platform: "web" }));

    if (!response) {
      throw new Error("Error starting analytics session");
    }

    try {
      sessionStorage.setItem("sessionID", response.sessionId);
      sessionStorage.setItem(
        "copilot-tests",
        JSON.stringify(response.activeTests),
      );
    } catch {
      console.log("Access to session storage not allowed");
    }

    setCookie("userID", response.userId);

    dispatch(setClientID(response.userId));
    dispatch(fetchSubscriptionGroup(response.userId));
    if (response.activeTests.includes("ai_coach_match_b")) {
      // Empty
    } else if (!trainerId) {
      dispatch(shouldShowIdentity());
    }

    if (data.referralCode || data.referralId)
      dispatch(getReferrerInfo(response.userId));

    return response;
  },
);

// extract to be triggered on useEffect on Survey
export const addTestToBA = createAsyncThunk(
  "analytics/addTestToBA",
  async (_, { getState }) => {
    const state = getState() as RootState;
    registerProp({ testVariant: state.analytics.activeTestIds });
    return;
  },
);

export const getInfluencerBySourceId = createAsyncThunk(
  "analytics/influencers",
  async (sourceId: string | undefined | null) => {
    const res = sourceId
      ? await apiGetInfluencerBySourceId(sourceId)
      : undefined;

    if (res && Object.keys(res).length === 0) {
      return undefined;
    }

    return res;
  },
);

export const fetchActiveTestIds = createAsyncThunk(
  "analytics/fetchActiveTestIds",
  async (
    data: {
      forceTestArray: string[];
      debugTest: string | undefined;
    },
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    const userId = state.client.clientID;
    const { forceTestArray } = data;

    if (!userId) {
      throw new Error("User Id cannot be undefined");
    }

    // Retrieve active tests from the db
    const activeTestIds = await api.analytics.getActiveTests(
      userId,
      forceTestArray.length > 0 ? forceTestArray : undefined,
    );

    dispatch(fetchSubscriptionGroup(userId));

    return activeTestIds;
  },
);

export const trackEvent = createAsyncThunk(
  "analytics/trackEvent",
  async (data: { event_type: string; event_content?: any }, { getState }) => {
    const state = getState() as RootState;

    const response = await api.analytics.trackEvent({
      userId: state.client.clientID ?? "help",
      sessionId: state.analytics.sessionID ?? "help",
      eventType: data.event_type,
      eventContent: data.event_content,
      platform: "web",
    });

    return response;
  },
);

export const getReferrerInfo = createAsyncThunk(
  "analytics/getReferrerInfo",
  async (userId: string) => {
    const referrerInfo = await api.clients.getReferrer(userId);
    return referrerInfo;
  },
);

export const smartRecoverEvent = createAsyncThunk(
  "analytics/sendSREvent",
  async (type: "lead" | "trial_start", { getState }) => {
    const state = getState() as RootState;

    if (
      !state.client.clientID ||
      !state.survey.firstName ||
      !state.survey.lastName ||
      !state.survey.email ||
      !state.survey.phoneNumber
    ) {
      throw new Error("Incorrect info for smartrecover event");
    }
    //

    let test = "";
    if (state.analytics.activeTestIds.includes("sr_offer_v3_a")) {
      test = "sr_offer_v3_applied_a";
    } else if (state.analytics.activeTestIds.includes("sr_offer_v3_b")) {
      test = "sr_offer_v3_applied_b";
    }

    let subInfo = "";

    if (state.payment.subscriptionGroup?.options.length) {
      subInfo = "Available";

      if (state.payment.subscriptionGroup?.options.length > 1) {
        subInfo += " plans: ";
      } else {
        subInfo += " plan: ";
      }

      const formattedOptions: string[] = [];

      state.payment.subscriptionGroup?.options.forEach((option) => {
        if (option.interval_count === 1 && option.interval === "month") {
          formattedOptions.push(`$${option.monthly_price}/mo`);
        } else if (option.interval_count === 1 && option.interval === "year") {
          formattedOptions.push(`$${option.monthly_price * 12}/yr`);
        } else {
          formattedOptions.push(
            `$${option.monthly_price * option.interval_count} / ${
              option.interval_count
            } ${option.interval}s`,
          );
        }
      });

      subInfo += formattedOptions.join(", ");
    } else {
      subInfo = "Error finding plans";
    }

    await api.clients.srWebhook({
      user_id: state.client.clientID,
      first_name: state.survey.firstName,
      last_name: state.survey.lastName,
      phone_number: state.survey.phoneNumber,
      email: state.survey.email,
      goals: state.survey.tagGoals,
      test: test,
      subscriptionInfo: subInfo,
      activity: type,
    });
  },
);

export const submitUserSource = createAsyncThunk(
  "analytics/submitUserSource",
  async (selectedUserSource: string, { getState }) => {
    const state = getState() as RootState;

    const response = await api.clients.updateSource(
      state.client.clientID!,
      selectedUserSource,
    );
    return response;
  },
);

export const submitUserSourceInfluencer = createAsyncThunk(
  "analytics/submitUserSourceInfluencer",
  async (selectedInfluencerId: string, { getState }) => {
    const state = getState() as RootState;

    const response = await api.clients.updateSourceInfluecner(
      state.client.clientID!,
      selectedInfluencerId,
    );
    return response;
  },
);

// Define a type for the slice state
interface AnalyticsState {
  sessionID?: string;
  sourceID: string | null;
  abTests: string[];
  activeTestIds: string[];
  visitedPages: string[];
  utm: {
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
    utmContent?: string;
  };
  referralCode: string | undefined;
  referralId: string | undefined;
  referralCTAId: string | undefined;
  referrerUserId: string | undefined;
  referralCampaign: string | undefined;
  referrerInfo: {
    firstName: string | undefined;
    lastName: string | undefined;
  };
  brand: string | undefined;
  status: {
    sesstionStatus: "idle" | "loading" | "active" | "failed";
    activeTestStatus: "idle" | "loading" | "succeeded" | "failed";
    influencerStatus: "idle" | "loading" | "succeeded" | "failed";
  };
  errors: {
    startSessionError: SerializedError | undefined;
    activeTestIdsError: SerializedError | undefined;
    influencerError: SerializedError | undefined;
  };
  influencer: ProjectedInfluencer | undefined;
  disableYoutubeSourceQuestion: boolean;
}

// Define the initial state using that type
const initialState: AnalyticsState = {
  sessionID: undefined,
  sourceID: null,
  abTests: [],
  activeTestIds: [],
  visitedPages: [],
  utm: {},
  referralCode: undefined,
  referralId: undefined,
  referralCTAId: undefined,
  referrerUserId: undefined,
  referralCampaign: undefined,
  referrerInfo: { firstName: undefined, lastName: undefined },
  brand: undefined,
  errors: {
    startSessionError: undefined,
    activeTestIdsError: undefined,
    influencerError: undefined,
  },
  status: {
    sesstionStatus: "idle",
    activeTestStatus: "idle",
    influencerStatus: "idle",
  },
  influencer: undefined,
  disableYoutubeSourceQuestion: false,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    resetAnalytics: () => initialState,
    setSessionID: (state, action: PayloadAction<string>) => {
      const sessionID = action.payload;

      state.sessionID = sessionID;
    },
    setABTests: (state, action: PayloadAction<string[]>) => {
      const abTests = action.payload;
      state.abTests = abTests;
    },
    setActiveTestIds: (state, action: PayloadAction<string[]>) => {
      const testIds = action.payload;
      state.status.activeTestStatus = "succeeded";
      state.activeTestIds = testIds;
    },
    addVisitedPage: (state, action: PayloadAction<string>) => {
      const visitedPath = action.payload;

      if (!state.visitedPages.includes(visitedPath)) {
        state.visitedPages.push(visitedPath);
      }
    },
    setBrand: (state, action: PayloadAction<string>) => {
      const brand = action.payload;

      state.brand = brand;
    },
    setDisableYoutubeSourceQuestion: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      const disableYoutubeSourceQuestion = action.payload;

      state.disableYoutubeSourceQuestion = disableYoutubeSourceQuestion;
    },
    setSourceID: (state, action: PayloadAction<string>) => {
      const sourceID = action.payload;

      state.sourceID = sourceID;
    },
    setRefferalInfo: (
      state,
      action: PayloadAction<{
        referralId?: string;
        referralCode?: string;
        referralCTAId?: string;
        referrerUserId?: string;
        referralCampaign?: string;
      }>,
    ) => {
      const {
        referralId,
        referralCode,
        referralCTAId,
        referrerUserId,
        referralCampaign,
      } = action.payload;

      state.referralId = referralId;
      state.referralCode = referralCode;
      state.referralCTAId = referralCTAId;
      state.referrerUserId = referrerUserId;
      state.referralCampaign = referralCampaign;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveTestIds.pending, (state, action) => {
      console.log("Redux: Fetching active test ids");

      state.status.activeTestStatus = "loading";
    });
    builder.addCase(fetchActiveTestIds.rejected, (state, action) => {
      console.log("Redux: Failed to fetch active test ids");

      state.errors.activeTestIdsError = action.error;
      state.status.activeTestStatus = "failed";
    });
    builder.addCase(fetchActiveTestIds.fulfilled, (state, action) => {
      console.log("Redux: Suceeded to fetch active test ids");

      const { forceTestArray, debugTest } = action.meta.arg;
      const activeTestIds = action.payload;

      if (debugTest) {
        console.log("Redux: Forcing test=> " + debugTest);
        state.activeTestIds = [debugTest];
      } else {
        forceTestArray.forEach((testId) => {
          if (activeTestIds.includes(testId)) {
            console.log("Redux: Forcing test=> " + testId);
          }
        });
        state.activeTestIds = activeTestIds;
        state.status.activeTestStatus = "succeeded";
      }
    });
    builder.addCase(startSession.pending, (state, action) => {
      console.log("Redux: Starting Session");

      state.status.sesstionStatus = "loading";
    });
    builder.addCase(startSession.rejected, (state, action) => {
      console.log("Redux: Failed to get subscription");

      state.errors.startSessionError = action.error;
      state.status.sesstionStatus = "failed";
    });
    builder.addCase(startSession.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const { sessionId, userId, activeTests } = action.payload;
      const {
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        forceTest,
        debugTest,
      } = action.meta.arg.data;
      console.log("Redux: Started session");
      console.log("user_id: " + userId);
      console.log("session_id: " + sessionId);

      state.utm.utmMedium = utmMedium;
      state.utm.utmContent = utmContent;
      state.utm.utmCampaign = utmCampaign;
      state.utm.utmSource = utmSource;

      state.sessionID = sessionId;

      if (debugTest) {
        console.log("Forcing debug test: " + debugTest);
        state.activeTestIds = [debugTest];
        state.status.activeTestStatus = "succeeded";
        return;
      }

      forceTest?.forEach((testId) => {
        if (activeTests.includes(testId)) {
          console.log("Forcing test: " + testId);
        }
      });

      state.activeTestIds = activeTests;

      state.status.sesstionStatus = "active";
      state.status.activeTestStatus = "succeeded";
    });
    builder.addCase(getReferrerInfo.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const { first_name, last_name } = action.payload;

      state.referrerInfo.firstName = first_name;
      state.referrerInfo.lastName = last_name;
    });
    builder.addCase(getInfluencerBySourceId.fulfilled, (state, action) => {
      state.influencer = action.payload;
      state.status.influencerStatus = "succeeded";
    });
    builder.addCase(getInfluencerBySourceId.rejected, (state, action) => {
      state.errors.influencerError = action.error;
    });
    builder.addCase(getInfluencerBySourceId.pending, (state, action) => {
      state.status.influencerStatus = "loading";
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetAnalytics,
  setSessionID,
  setABTests,
  setActiveTestIds,
  addVisitedPage,
  setRefferalInfo,
  setSourceID,
  setBrand,
  setDisableYoutubeSourceQuestion,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const selectActiveTests = (state: RootState) =>
  state.analytics.activeTestIds;

export const selectSessionStatus = (state: RootState) =>
  state.analytics.status.sesstionStatus;

export const selectActiveTestIdsStatus = (state: RootState) =>
  state.analytics.status.activeTestStatus;

export const selectReferrerName = (state: RootState) =>
  state.analytics.referrerInfo.firstName;
