import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ProjectedInfluencer } from "@trainwell/features/influencers";
import React, { Fragment } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectActiveTests } from "src/slices/analyticsSlice";
import CTAButton from "../Layout/CTAButton";

type SplashPageYoutubeProps = {
  influencer: ProjectedInfluencer;
  formElement?: React.ReactNode | undefined;
  onClick: () => void;
};

export default function SplashPageYoutubeV2({
  influencer,
  formElement,
  onClick = () => {},
}: SplashPageYoutubeProps) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  const activeTestsIds = useAppSelector(selectActiveTests);

  const {
    display_name,
    assets: { splash_asset },
  } = influencer;

  return (
    <Container maxWidth={isPhone ? "sm" : "md"}>
      {isPhone && <YoutubePageTitleV1 name={display_name} />}
      <Box
        sx={{
          display: "flex",
          mt: { xs: 4, md: 8 },
          justifyItems: "center",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
            height: { xs: 300, md: 510 },
            width: { xs: 266, md: 450 },
            mr: { xs: 2, md: 4 },
            mb: { xs: 3, md: 0 },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: { xs: 300, md: 510 },
              width: { xs: 266, md: 450 },
            }}
          >
            <img
              src={splash_asset}
              width={"100%"}
              height={"100%"}
              sizes="(max-width: 900px) 60vw,
              (max-width: 1200px) 70vw,
              40vw"
              alt=""
              loading="eager"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {!isPhone && <YoutubePageTitleV1 name={display_name} />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "270px",
              mx: "auto",
              mb: 2,
            }}
          >
            <Typography align="center">
              <b>{`We're happy you are here! 😊`}</b>
              <br></br>
              {`Let's get started by matching you with a trainer.`}
            </Typography>
          </Box>

          {formElement ? (
            formElement
          ) : (
            <Fragment>
              <CTAButton onClick={onClick} CTAText={`Find your trainer`} />
            </Fragment>
          )}
        </Box>
      </Box>
    </Container>
  );
}

function YoutubePageTitleV1({ name }: { name: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mb: 2.5,
      }}
    >
      <Typography
        // @ts-expect-error
        variant="YTBig"
        sx={{
          fontSize: { xs: 33, md: 42 },
          textAlign: "center",
          mb: { xs: 1, md: 2 },
          fontWeight: "bold",
          lineHeight: 1,
          color: "#443564",
        }}
      >
        {name}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img height={42} width={42} src={"/assets/checkmark.png"} alt="" />
        <Typography
          // @ts-expect-error
          variant="YTBig"
          sx={{
            fontSize: { xs: 33, md: 42 },
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: 1,
            color: "#443564",
            ml: 1,
          }}
        >
          {` Approved`}
        </Typography>
      </Box>
    </Box>
  );
}
